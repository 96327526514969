import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 828.000000 717.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} /> 
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,717.000000) scale(0.100000,-0.100000)">
        <path d="M3395 5103 c-11 -2 -55 -11 -97 -19 -134 -25 -201 -51 -251 -94 -51
-46 -111 -131 -131 -188 l-14 -39 75 -7 c42 -3 113 -6 158 -6 l83 0 22 63 c26
71 86 183 104 194 6 5 19 21 29 36 10 15 29 33 43 39 42 20 30 32 -21 21z"/>
<path d="M3980 4728 c-137 -12 -186 -23 -229 -51 -43 -29 -105 -102 -147 -173
-14 -24 -30 -44 -35 -44 -5 0 -9 -6 -9 -14 0 -8 -22 -58 -49 -112 -167 -333
-291 -790 -291 -1075 0 -114 13 -172 44 -192 34 -22 243 -87 280 -87 1 0 -4
30 -12 68 -22 107 1 332 59 567 27 110 130 385 199 531 53 112 71 142 166 282
35 52 64 68 64 35 0 -15 2 -16 10 -3 13 20 13 8 0 -25 -7 -18 -7 -39 0 -65 5
-20 13 -64 16 -96 3 -33 8 -65 10 -73 10 -30 4 -755 -6 -765 -6 -6 -10 -23
-10 -38 0 -24 4 -28 28 -28 46 0 344 29 349 34 3 3 16 49 28 103 49 204 128
410 211 548 45 76 64 91 64 51 0 -13 4 -27 9 -30 4 -3 10 -67 13 -141 5 -117
8 -138 24 -151 14 -11 15 -17 7 -20 -17 -7 -16 -29 1 -79 10 -26 20 -39 31
-39 12 2 13 -1 5 -11 -8 -10 -3 -19 22 -38 84 -61 182 -68 318 -23 105 36 153
65 184 114 25 37 53 128 44 138 -8 7 -191 -27 -205 -39 -7 -5 -13 -24 -13 -41
0 -47 -18 -103 -36 -110 -38 -15 -61 4 -74 60 -8 30 -17 57 -22 60 -4 3 -8 20
-8 37 0 18 -5 48 -10 67 -6 19 -11 69 -11 110 -1 41 -7 96 -14 122 -6 26 -9
45 -4 43 14 -9 -3 80 -24 125 -39 86 -73 98 -218 80 -82 -10 -128 -20 -89 -20
7 0 10 -3 7 -6 -4 -4 -13 -1 -21 5 -35 29 -95 12 -170 -48 -38 -31 -60 -33
-49 -6 4 11 1 13 -11 9 -9 -3 -16 -1 -16 5 0 6 4 10 9 9 5 -2 10 -1 11 2 5 11
-13 116 -23 135 -6 11 -10 32 -9 48 1 15 -7 45 -18 67 -11 21 -20 48 -20 58 0
21 -48 100 -75 122 -20 17 -147 21 -285 8z m177 -74 c-3 -3 -12 -4 -19 -1 -8
3 -5 6 6 6 11 1 17 -2 13 -5z m129 -16 c-12 -7 -21 -7 -28 0 -6 6 0 10 18 10
23 0 24 -2 10 -10z m41 -24 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13
-5z m20 -96 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m13 -48 c0
-11 -4 -20 -9 -20 -5 0 -7 9 -4 20 3 11 7 20 9 20 2 0 4 -9 4 -20z m525 -150
c-3 -5 -12 -10 -18 -10 -7 0 -6 4 3 10 19 12 23 12 15 0z m-28 -26 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-140 -96 c-3 -7 -5 -2 -5 12 0 14
2 19 5 13 2 -7 2 -19 0 -25z m-357 -23 c-7 -9 -15 -13 -17 -11 -7 7 7 26 19
26 6 0 6 -6 -2 -15z m-80 -125 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10
11 10 2 0 4 -4 4 -10z m-63 -122 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m770 -150 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
m-190 -70 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m58 -68 c-3
-5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z"/>
<path d="M5250 4110 c-51 -11 -91 -28 -128 -57 -47 -36 -32 -41 17 -5 85 62
180 66 284 13 70 -36 89 -35 25 3 -77 45 -138 59 -198 46z"/>
<path d="M5273 4053 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M4210 2615 l0 -335 85 0 c84 0 85 0 85 25 0 30 4 31 37 5 87 -68 230
-35 290 67 36 61 44 181 18 250 -22 58 -77 119 -118 132 -72 22 -134 18 -181
-14 -20 -14 -39 -25 -41 -25 -3 0 -5 52 -5 115 l0 115 -85 0 -85 0 0 -335z
m317 10 c28 -19 46 -84 37 -130 -7 -38 -53 -85 -84 -85 -55 0 -100 52 -100
115 0 41 22 89 47 104 26 15 76 13 100 -4z"/>
<path d="M1310 2595 l0 -315 50 0 50 0 0 218 c0 120 3 222 7 226 4 4 50 -78
103 -181 l95 -188 37 -3 36 -3 99 193 98 193 5 -225 5 -225 53 -3 52 -3 0 315
0 316 -67 0 -68 0 -100 -200 c-55 -109 -105 -199 -110 -200 -6 0 -55 90 -110
200 l-100 200 -67 0 -68 0 0 -315z"/>
<path d="M2770 2805 l0 -65 -36 0 c-35 0 -35 -1 -32 -37 3 -35 6 -38 36 -41
l32 -3 0 -135 c0 -142 9 -183 48 -217 34 -31 90 -39 151 -21 l53 15 -7 37 c-9
51 -10 51 -54 39 -34 -9 -43 -8 -61 8 -18 16 -20 30 -20 147 l0 128 70 0 70 0
0 40 0 40 -70 0 -70 0 0 65 0 65 -55 0 -55 0 0 -65z"/>
<path d="M3236 2773 c-38 -6 -101 -47 -122 -78 -24 -37 -29 -97 -10 -133 22
-43 61 -67 155 -99 70 -23 82 -29 79 -47 -3 -19 -9 -21 -60 -20 -39 1 -74 9
-107 26 l-49 24 -26 -51 c-30 -60 -27 -66 59 -96 86 -30 151 -34 218 -15 106
31 153 102 125 189 -16 51 -56 80 -149 108 -82 25 -105 40 -95 64 5 15 15 17
60 12 29 -3 72 -15 94 -26 23 -12 43 -18 45 -13 3 4 16 28 30 55 24 44 24 48
8 57 -64 34 -187 55 -255 43z"/>
<path d="M5213 2770 c-119 -24 -193 -120 -193 -251 0 -68 21 -120 70 -171 79
-82 201 -98 312 -42 39 20 88 68 88 88 0 3 -27 19 -61 36 l-60 31 -30 -26
c-43 -35 -92 -30 -126 14 -21 27 -16 124 8 155 26 33 84 36 122 6 l26 -21 61
31 c67 34 68 40 13 92 -52 50 -148 74 -230 58z"/>
<path d="M5733 2769 c-128 -17 -213 -113 -213 -242 0 -107 43 -178 137 -223
105 -52 253 -32 325 44 57 59 72 98 72 177 0 171 -130 269 -321 244z m98 -146
c37 -22 53 -62 46 -120 -9 -79 -85 -120 -144 -78 -53 37 -50 152 4 194 31 25
56 26 94 4z"/>
<path d="M6423 2770 c-34 -8 -62 -26 -76 -47 -15 -25 -37 -14 -37 17 l0 30
-85 0 -85 0 0 -245 0 -245 85 0 85 0 0 123 c0 179 15 217 89 217 20 0 36 -8
50 -26 19 -24 21 -40 21 -170 l0 -144 85 0 85 0 0 135 c0 175 13 205 89 205
53 0 60 -21 63 -185 l3 -150 88 -3 87 -3 0 163 c0 175 -8 227 -38 267 -65 88
-215 85 -276 -6 l-23 -34 -27 36 c-15 19 -37 42 -49 49 -29 18 -94 26 -134 16z"/>
<path d="M3580 2603 c0 -93 5 -186 11 -208 33 -118 185 -162 274 -80 34 32 47
32 43 -2 l-3 -28 88 -3 87 -3 0 246 0 245 -85 0 -85 0 0 -122 c0 -180 -16
-218 -88 -218 -55 0 -62 20 -62 188 l0 152 -90 0 -90 0 0 -167z"/>
<path d="M2142 2577 c3 -182 3 -184 31 -226 61 -92 199 -100 276 -17 29 31 41
26 41 -20 l0 -35 53 3 52 3 1 219 c1 121 -1 228 -4 238 -3 14 -14 18 -52 18
l-48 0 -4 -142 c-3 -129 -5 -147 -26 -180 -30 -49 -83 -67 -143 -49 -60 18
-69 48 -69 226 l0 145 -56 0 -56 0 4 -183z"/>
<path d="M4860 2433 c-65 -24 -80 -95 -29 -137 34 -29 50 -32 82 -14 32 17 46
39 46 73 1 53 -54 95 -99 78z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
